import { produce } from "immer"

const initState = {
  all_report_id: [],
  monthly_report_id: [],
  report_id_by_id: {},
  new_report_id: {},
  delete_report_id: {},
  update_report_id: {},
  report_group_by_project_no: {},
  new_report_group: {},
  delete_report_group: false,
  update_report_group: {},
  report_by_project_no: {},
  new_report: {},
  report_by_only_project_no: [],
  report_by_report_group_no: [],
  monthly_report_by_report_group_no: [],
  report_dashboard_by_project_no: null,
  new_report_dashboard: null,
  delete_report_dashboard: false,
  security_reports: [],
  report_arg_no: [],
  azure_ad_user_activities: {},
  azure_ad_acsi: {},
  azure_ad_check_record: {},
  azure_ad_acsi_pdf_excel: {},
  azure_ad_edc_word: {},
  azure_waf_acsi: {},
  aws_waf_acsi: {},
  azure_waf_edc_word: {},
  aws_waf_edc_word: {},
  soc_monthly_report_word: {},
  monthly_report_word: {},
  azure_ad_user_activities_pdf_excel: {},
  azure_ad_check_record_pdf_excel: {},
  errorcode: null,
  get_all_report_schedule: [],
  get_report_schedule: null,
  update_report_schedule: null,
  search_all_report_schedule: [],
  search_all_monthly_report_schedule: [],
  update_monthly_report_schedule: null,
  get_monthly_report_schedule: null,
  azure_sentinel_rule_activity_pdf_excel_wistron: {},
  azure_sentinel_rule_activity_wistron: [],
  azure_sentinel_rule_activity_pdf_excel: {},
  azure_sentinel_rule_activity: [],
  azure_firewall_edc_word: {},
  azure_firewall_edc: {},
  azure_on_premises_firewall_edc: {},
  service_portal_report_list: [],
  service_portal_report_by_id: null,
  expand_soc_data: [],
  security_report_direction: null,
  new_msg_att_file_data: [],
  new_incident_alert_data: [],
  tickets_detail_excel: {},
  soc_name_level_alert_product_excel: {},
  log_account_excel: {},
  user_trigger_info_excel: {},
  incident_top_ten_excel: {},
};

const reportReducer = produce((state = initState, action) => {
  switch (action.type) {
    case 'Error':
      // console.log(action)
      return {
        ...state,
        // tickets: null,
        errorcode: action.payload ? action.payload : 500,
      };

    case 'FindAllReportID_SUCCESS':
      return {
        ...state,
        all_report_id: action.payload,
        errorcode: null,
      };
    case 'FindMonthlyReportID_SUCCESS':
      return {
        ...state,
        monthly_report_id: action.payload,
        errorcode: null,
      };
    case 'FindReportIDByID_SUCCESS':
      return {
        ...state,
        report_id_by_id: action.payload,
        errorcode: null,
      };
    case 'CreateReportID_SUCCESS':
      return {
        ...state,
        new_report_id: action.payload,
        errorcode: null,
      };
    case 'DeleteReportID_SUCCESS':
      return {
        ...state,
        delete_report_id: action.payload,
        errorcode: null,
      };
    case 'UpdateReportID_SUCCESS':
      return {
        ...state,
        update_report_id: action.payload,
        errorcode: null,
      };
    case 'GetAllReportGroup':
      return {
        ...state,
        report_group_by_project_no: action.payload,
        errorcode: null,
      };

    case 'CreateReportGroup_SUCCESS':
      return {
        ...state,
        new_report_group: action.payload,
        errorcode: null,
      };
    case 'DeleteReportGroup_SUCCESS':
      return {
        ...state,
        delete_report_group: action.payload,
        errorcode: null,
      };
    case 'UpdateReportGroup_SUCCESS':
      return {
        ...state,
        update_report_group: action.payload,
        errorcode: null,
      };
    case 'GetAllReport':
      const GetAllReportKey = `${action.project_no}_${action.category_no}`;
      state.report_by_project_no[GetAllReportKey] = action.payload;
      return {
        ...state,
        report_by_project_no: {
          ...state.report_by_project_no,
        },
        errorcode: null,
      };
    case 'CreateReport_SUCCESS':
      return {
        ...state,
        new_report: action.payload,
        errorcode: null,
      };
    case 'FindReportByProjectNo_SUCCESS':
      return {
        ...state,
        report_by_only_project_no: action.payload,
        errorcode: null,
      };
    case 'GetReportByReportGroupNo':
      return {
        ...state,
        report_by_report_group_no: action.payload,
        errorcode: null,
      };
    case 'GetMonthlyReportByReportGroupNo':
      return {
        ...state,
        monthly_report_by_report_group_no: action.payload,
        errorcode: null,
      };

    case 'UpdateMonthlyReportSchedule_SUCCESS':
      return {
        ...state,
        update_monthly_report_schedule: action.payload,
        errorcode: null,
      };

    case 'GetReportDashboardByProjectNo_SUCCESS':
      return {
        ...state,
        report_dashboard_by_project_no: action.payload,
        errorcode: null,
      };
    case 'CreateReportDashboard_SUCCESS':
      return {
        ...state,
        new_report_dashboard: action.payload,
        errorcode: null,
      };
    case 'DeleteReportDashboard_SUCCESS':
      return {
        ...state,
        delete_report_dashboard: action.payload,
        errorcode: null,
      };
    case 'GetSecurityReportByTicketNoAndProjectNo':
      state.security_reports = [...state.security_reports, action.security_report];
      return state;
    // return {
    //   ...state,
    //   security_reports: state.security_reports,
    //   errorcode: null,
    // }
    case 'FindReportArgNoByReportId_SUCCESS':
      return {
        ...state,
        report_arg_no: action.payload,
        errorcode: null,
      };
    case 'FindAzureADUserActivitiesReport_SUCCESS':
      return {
        ...state,
        azure_ad_user_activities: action.payload,
        errorcode: null,
      };
    case 'FindAzureADACSIReport_SUCCESS':
      return {
        ...state,
        azure_ad_acsi: action.payload,
        errorcode: null,
      };
    case 'FindGcpCloudAuditACSIReport_SUCCESS':
      return {
        ...state,
        gcp_cloud_audit_edc: action.payload,
        errorcode: null,
      };
    case 'FindAzureWafACSIReport_SUCCESS':
      return {
        ...state,
        azure_ad_acsi: action.payload,
        errorcode: null,
      };
    case 'FindAwsWafACSIReport_SUCCESS':
      return {
        ...state,
        azure_ad_acsi: action.payload,
        errorcode: null,
      };
    // case 'FindGcpAzureADACSIReport_SUCCESS':
    //   return {
    //     ...state,
    //     azure_ad_acsi: action.payload,
    //     errorcode: null,
    //   };
    case 'FindAzureADCheckRecordReport_SUCCESS':
      return {
        ...state,
        azure_ad_check_record: action.payload,
        errorcode: null,
      };
    case 'DownloadADAcsiPdfExcel_SUCCESS':
      return {
        ...state,
        azure_ad_acsi_pdf_excel: action.payload,
        errorcode: null,
      };
    case 'DownloadAzureADEdcWord_SUCCESS':
      return {
        ...state,
        azure_ad_edc_word: action.payload,
        errorcode: null,
      };
    case 'DownloadGcpAzureADEdcWord_SUCCESS':
      return {
        ...state,
        gcp_azure_ad_edc_word: action.payload,
        errorcode: null,
      };
    case 'DownloadGcpCloudAuditEdcWord_SUCCESS':
      return {
        ...state,
        gcp_cloud_audit_edc_word: action.payload,
        errorcode: null,
      };
    case 'DownloadAzureWafEdcWord_SUCCESS':
      return {
        ...state,
        azure_waf_edc_word: action.payload,
        errorcode: null,
      };

    case 'DownloadGcpAzureWafEdcWord_SUCCESS':
      return {
        ...state,
        gcp_azure_waf_edc_word: action.payload,
        errorcode: null,
      };
    case 'DownloadAwsWafEdcWord_SUCCESS':
      return {
        ...state,
        aws_waf_edc_word: action.payload,
        errorcode: null,
      };
    case 'DownloadGcpAwsWafEdcWord_SUCCESS':
      return {
        ...state,
        gcp_aws_waf_edc_word: action.payload,
        errorcode: null,
      };
    case 'DownloadSocMonthlyReportWord_SUCCESS':
      return {
        ...state,
        soc_monthly_report_word: action.payload,
        errorcode: null,
      };
    case 'DownloadMonthlyReportWord_SUCCESS':
      return {
        ...state,
        monthly_report_word: action.payload,
        errorcode: null,
      };
    case 'DownloadADUserActivitiesPdfExcel_SUCCESS':
      return {
        ...state,
        azure_ad_user_activities_pdf_excel: action.payload,
        errorcode: null,
      };
    case 'DownloadADCheckRecordPdfExcel_SUCCESS':
      return {
        ...state,
        azure_ad_check_record_pdf_excel: action.payload,
        errorcode: null,
      };
    case 'GetAllReportSchedule_SUCCESS':
      return {
        ...state,
        get_all_report_schedule: action.payload,
        errorcode: null,
      };
    case 'GetReportSchedule_SUCCESS':
      return {
        ...state,
        get_report_schedule: action.payload,
        errorcode: null,
      };
    case 'UpdateReportSchedule_SUCCESS':
      return {
        ...state,
        update_report_schedule: action.payload,
        errorcode: null,
      };
    case 'SearchReportScheduleByAcctNo_SUCCESS':
      return {
        ...state,
        search_all_report_schedule: action.payload,
        errorcode: null,
      };

    case 'SearchMonthlyReportScheduleByAcctNo_SUCCESS':
      return {
        ...state,
        search_all_monthly_report_schedule: action.payload,
        errorcode: null,
      };
    case 'GetMonthlyReportSchedule_SUCCESS':
      return {
        ...state,
        get_monthly_report_schedule: action.payload,
        errorcode: null,
      };
    case 'GetSentinelRuleActivityWistron_SUCCESS':
      return {
        ...state,
        azure_sentinel_rule_activity_wistron: action.payload,
        errorcode: null,
      };
    case 'DownloadSentinelRuleActivityReportWistron_SUCCESS':
      return {
        ...state,
        azure_sentinel_rule_activity_pdf_excel_wistron: action.payload,
        errorcode: null,
      };
    case 'GetSentinelRuleActivity_SUCCESS':
      return {
        ...state,
        azure_sentinel_rule_activity: action.payload,
        errorcode: null,
      };
    case 'DownloadSentinelRuleActivityReport_SUCCESS':
      return {
        ...state,
        azure_sentinel_rule_activity_pdf_excel: action.payload,
        errorcode: null,
      };
    case 'DownloadAzureFirewallEdcWord_SUCCESS':
      return {
        ...state,
        azure_firewall_edc_word: action.payload,
        errorcode: null,
      };
    case 'GetAllAzureFireWallEdc_SUCCESS':
      return {
        ...state,
        azure_firewall_edc: action.payload,
        errorcode: null,
      };

    case 'GetAllAzureOnPremisesFireWallEdc_SUCCESS':
      return {
        ...state,
        azure_on_premises_firewall_edc: action.payload,
        errorcode: null,
      };

    case 'DownloadTicketsDetailExcel_SUCCESS':
      return {
        ...state,
        tickets_detail_excel: action.payload,
        errorcode: null,
      };
    case 'DownloadSocNameLevelAlertProductExcel_SUCCESS':
      return {
        ...state,
        soc_name_level_alert_product_excel: action.payload,
        errorcode: null,
      };
    case 'DownloadLogAccountReportExcel_SUCCESS':
      return {
        ...state,
        log_account_excel: action.payload,
        errorcode: null,
      };
    case 'DownloadUserTriggerInformationExcel':
      return {
        ...state,
        user_trigger_info_excel: action.payload,
        errorcode: null,
      };
    case 'DownloadSocIncidentTopTenPdfExcel_SUCCESS':
      return {
        ...state,
        incident_top_ten_excel: action.payload,
        errorcode: null,
      };
    case 'GetReportTitleByOid_SUCCESS':
      return {
        ...state,
        service_portal_report_list: action.payload,
        errorcode: null,
      };
    case 'GetReportFileById_SUCCESS':
      return {
        ...state,
        service_portal_report_by_id: action.payload,
        errorcode: null,
      };
    case 'GetExpandSocReport_SUCCESS':
      return {
        ...state,
        expand_soc_data: action.payload,
        errorcode: null,
      };
    case 'FindSecurityReportDirection_SUCCESS':
      return {
        ...state,
        security_report_direction: action.payload,
        errorcode: null,
      };
    case 'GetNewMsgAttFileData_SUCCESS':
      return {
        ...state,
        new_msg_att_file_data: action.payload,
        errorcode: null,
      };
    case 'GetIncidentAlertData_SUCCESS':
      return {
        ...state,
        new_incident_alert_data: action.payload,
        errorcode: null,
      };
    default:
      return state;
  }
})

export default reportReducer
